@import url("https://getbootstrap.com/docs/5.0/dist/css/bootstrap.min.css");
/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap');
@import url('//cdn.datatables.net/1.13.4/css/jquery.dataTables.min.css');
@import url('//cdnjs.cloudflare.com/ajax/libs/jqueryui/1.12.1/jquery-ui.min.css');

body {
  padding: 0;
    margin: 0;
    background-color: #fafdfb !important;
    font-size: 12px;
    font-family: 'Poppins', sans-serif;
    overflow-x: hidden;
}

.content {
  color: #cbcbcb;
  width: 100%;
  padding-left: 255px;
  padding-top: 56px;
  margin-bottom: 32px;
}

.loc_content{
  padding: 20px;
}

.loc_content h1{
    color: #5a5c69;
    font-size: 20px;
    padding: 25px;
    font-weight: 400;
}

.loc_content .card h2{
  color: #5a5c69;
  font-size: 18px;
  padding: 0;
  font-weight: 400;
}

.loc_content h2{
  color: #5a5c69;
  font-size: 18px;
  padding: 25px 0;
  font-weight: 400;
  margin-top: 0;
}

h2 {
  color: #000000;
  font-weight: 500;
  font-size: 20px;
  margin: 40px 40px 0 40px;
}

input[type="text"], input[type="number"]{
  font-size:13px !important;
}

select{
  background-color: white;
  border: solid 1px #eee;
  border-radius: 4px;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;
  margin: 0;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%), radial-gradient(#fff 70%, transparent 72%);
  background-position: calc(100% - 15px) calc(1em + 2px), calc(100% - 10px) calc(1em + 2px), calc(100% - 0.5em) 1.5em;
  background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
  background-repeat: no-repeat;
  font-size:13px !important;
}

.notfound{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: 0;
  width: 70%;
}

.notfound h2{
  display: inline-block;
  font-size: 30px;
  width: 75%;
  margin-top: 0;
  border-bottom: solid 1px #dedede;
  padding-bottom:15px;
  margin-bottom:10px;
}

.notfound p{
  display: inline-block;
  font-size: 14px;
  color: rgb(51, 51, 51);
  margin-left: 40px;
  margin-top: 10px;
}

.notfound img{
  width: 15%;
  border-radius: 100%;
  display: inline-block;
  vertical-align: middle;
}

.notfound-text{
  display: inline-block;
  vertical-align: middle;
  width:70%;
}

.notfound .btn-goback{
  background:#00529c;
  color:#ffffff;
  border:none;
  display: inline-block;
  padding: 7px 20px;
  margin-left:40px;
  margin-top: 10px;
}

.noaccess{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: 0;
  width: 70%;
}

.noaccess h2{
  display: inline-block;
  font-size: 30px;
  width: 75%;
  margin-top: 0;
  border-bottom: solid 1px #dedede;
  padding-bottom:15px;
  margin-bottom:10px;
}

.noaccess p{
  display: inline-block;
  font-size: 14px;
  color: rgb(51, 51, 51);
  margin-left: 40px;
  margin-top: 10px;
}

.noaccess img{
  width: 15%;
  display: inline-block;
  vertical-align: middle;
}

.noaccess-text{
  display: inline-block;
  vertical-align: middle;
  width:70%;
}

.noaccess .btn-goback{
  background:#00529c;
  color:#ffffff;
  border:none;
  display: block;
  padding: 7px 20px;
  margin-left:40px;
  margin-top: 10px;
}

.goBackBtn{
  background: transparent;
  display:inline-block;
  cursor: pointer;
  border: none;
}

.goBackBtn i{
  color: #555;
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, 
              rgb(0 0 0 / 14%) 0px 2px 2px 0px, 
              rgb(0 0 0 / 12%) 0px 1px 5px 0px;
  border-radius: 100%;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}