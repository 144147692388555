.uploadfield{
    padding: 0px 20px;
    margin: 20px;
}

.uploadfield input{
    border: 1px solid #ced4da !important;
    padding: 5px !important;
    background: #ffffff;
}

.current-video{
    border: 1px solid #ced4da !important;
    padding: 20px !important;
    background: #ffffff;
    margin: 20px;
    color:#000;
    font-size: 13px;
}

.uploadfield .btn-upload-video{
    background-color: #00529c;
    color: #ffffff;
    font-size: 13px;
    border-radius: 0;
}

#accordion .card{
    background:transparent !important;
    border:none;
}

#accordion .card-header{
    background:transparent !important;
    border-bottom:none;
}

.card-header, .card-body{
    padding:0;
}

.settingsTable{
    margin: 0px 20px;
    background: rgb(255, 255, 255);
    box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15)!important;
    padding: 20px 30px;
    border-radius: 10px;
}

.settingsTable table tr td{
    padding: 15px 20px;
}

.settingsTable table tr{
    border-bottom:solid 1px #ededed;
}

.settingsTable table tr td i{
    position: absolute;
    right: 90px;
    margin-top: -23px;
}

/* .settingsTable select{
    border: solid 1px #eee;
    font-size: 13px;
} */

.settingsTable select{
    /* styling */
      background-color: white;
      border: solid 1px #eee;
      border-radius: 4px;
      display: inline-block;
      font: inherit;
      line-height: 1.5em;
      padding: 0.5em 3.5em 0.5em 1em;
    
      /* reset */
    
      margin: 0;      
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-appearance: none;
      -moz-appearance: none;
    
      background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%), radial-gradient(#fff 70%, transparent 72%);
      background-position: calc(100% - 15px) calc(1em + 2px), calc(100% - 10px) calc(1em + 2px), calc(100% - 0.5em) 1.5em;
      background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
      background-repeat: no-repeat;
    }

#accordion .card h2{
    margin: 40px 65px 0;
}

.btnSave{
    position: absolute;
    right: 20px;
    margin-top: -22px;
}

.modulesTable{
    margin: 0px 25px 0px 25px;
}

.modulesTable table thead{
    display:none;
}

.modulesTable table tr{
    background: #ffffff;
}

.modulesTable table tr td{
    border-bottom: 1px solid #efefef;
    padding: 20px;
}

.modulesTable .modulesCategory{
    font-size: 15px;
    margin-top: 20px;
    font-weight: 300;
    color: #777777;
    margin-bottom: 10px;
}