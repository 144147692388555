.center{
    font-family: 'Open Sans', sans-serif;
    background-image: linear-gradient(90deg,#00529c,#003c72);
    position: absolute;
    height: 100%;
    width: 100%;
    background-size: cover;
    left:0;
    top:0;
    z-index: 999999;
}

.login{
    background: #ffffff;
    width: 400px;
    padding: 30px;
    box-shadow: 0 0 25px rgba(0,0,0,0.07);
    position: absolute;
    left: 0;
    margin: auto;
    right: 0;
}

.card > form{
    display: flex;
    flex-direction: column;
}

.login form{
    border-bottom: solid 1px #ddd;
    padding-bottom: 20px;
}

.card h1{
    text-align: center;
    margin-top: 0;
    margin-bottom: 10px;
}

.login select{
    width: 83%;
    margin-left:10px;
}

.login select, .login input[type="text"], .login input[type="password"] {
    border-radius: 0px;
    padding: 8px 10px;
    width: 100%;
    border: 1px solid #ced4da;
    font-size: 13px;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-image: none;
    background-clip: padding-box;
    margin-bottom: 1rem;
    font-family: 'Open Sans', sans-serif;
}

.form-submit{
    background-color: #00529c;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    letter-spacing: 1px;
    width: 100%;
}
.form-submit:hover{
   opacity: 0.6;
}

.login_logo{
    margin: auto;
    display: block;
    width: 400px;
    margin-top: 100px;
    background: #ffffff;
    padding: 30px 70px 10px 70px;
    position: relative;
    z-index: 10;
}

.verification{
    display:none;
    background:#ffffff;
    width: 400px;
    padding: 65px 30px;
    box-shadow: 0 0 25px rgba(0,0,0,0.07);
    position: absolute;
    left: 20%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.verification h2{
    margin: 0 0 20px 0;
}

.verification canvas{
    margin: auto;
    display: block;
}

.verification .verify{
    background-color: #00529c;
    color: #ffffff;
    border: none;
    padding: 5px 20px;
    font-size: 14px;
}

.verification p{
    font-size:14px;
    margin-bottom:30px;
}

.btn-azure{
    background-color: #00529c;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    display: block;
    margin: auto;
    margin-top: 20px;
    width:100%;
    cursor: pointer;
}

.btn-azure i{
    margin-right: 5px;
    color: #00adef;
    font-size: 15px;
}

.login span{
    width: 100%;
    text-align: center;
    display: block;
    margin-top: 20px;
    color: #aaa;
}

.err_msg, .msg{
    display:none;
    padding: 10px 0;
    color: #ff0000;
    width: 100%;
    text-align: center;
    background: rgba(255,0,0,0.2);
    border: solid 1px #ff0000;
    margin-top: 20px;
}

.api-status{
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #FF0000;
    position: absolute;
    bottom: -12px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    border: solid 5px #ffffff;
}

@media screen and (min-width:769px) and (max-width:1024px){
    .login {
        position: absolute;
        transform: translate(0%, -50%);
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
    }

    .content{
        padding-left:0;
    }
}

@media screen and (min-width:1025px) and (max-width:1280px){
    
}

