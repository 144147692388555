.switch {
	position: relative;
	display: inline-block;
	width: 50px;
	height: 20px;
  }
  
  .switch input { 
	opacity: 0;
	width: 0;
	height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    /* top: 0;
    left: 0; */
    right: 0;
    bottom: 0;
    background-color: #ccc;
    box-sizing: border-box;
    width: 32px;
    height: 14px;
    border-radius: 7px;
    transition: opacity 90ms cubic-bezier(0.4, 0, 0.2, 1) 0s, background-color 90ms cubic-bezier(0.4, 0, 0.2, 1) 0s, border-color 90ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    transition: .4s;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    border: 10px solid;
    pointer-events: none;
    z-index: 1;
    background-color: #ffffff;
    border-color: #ffffff;
    left: -7px;
    top: -3px;
  }
  
  input:checked + .slider {
	background-color: #0085CA;
  }
  
  input:focus + .slider {
	box-shadow: 0 0 1px #0085CA;
  }
  
  input:checked + .slider:before {
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(26px);
  }
  
  .slider.round {
	border-radius: 34px;
  }
  
  .slider.round:before {
	border-radius: 50%;
  }

  .btn-delete{
    box-shadow: rgba(0, 0, 0, 0.2) 3px 3px 5px -1px, rgba(0, 0, 0, 0.14) 3px 3px 5px -1px, rgba(0, 0, 0, 0.12) 3px 3px 5px -1px;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    line-height: 30px !important;
    text-align: center;
    color: #ffffff;
    background: #ff0000;
    font-size: 12px;
  }

  .btn-confirm{
    background: orange;
    border:none;
    color: #ffffff;
    padding: 7px 20px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.2) 3px 3px 5px -1px, rgba(0, 0, 0, 0.14) 3px 3px 5px -1px, rgba(0, 0, 0, 0.12) 3px 3px 5px -1px;
    border-radius: 20px;
  }

  .btn-edit{
    background: orange;
    color: #ffffff;
    padding: 7px;
    border-radius: 50%;
    margin-right: 10px;
  }

  .btn-add, .btn-save{
    background-color: #5cb85c;
    color: #ffffff;
    border: none;
    padding: 7px 20px;
    float: right;
    margin-right: 40px;
    margin-top: 15px !important;
    font-size: 14px;
    box-shadow: rgba(0, 0, 0, 0.2) 3px 3px 5px -1px, rgba(0, 0, 0, 0.14) 3px 3px 5px -1px, rgba(0, 0, 0, 0.12) 3px 3px 5px -1px;
    border-radius: 20px;
    line-height: 25px;
    font-weight: 300;
  }

  .btn-blue{
    background-color: #0085CA;
    color: #ffffff;
    border: none;
    padding: 7px 20px;
    float: right;
    margin-left:-20px;
    margin-right: 40px;
    margin-top: 15px !important;
    font-size: 14px;
    box-shadow: rgba(0, 0, 0, 0.2) 3px 3px 5px -1px, rgba(0, 0, 0, 0.14) 3px 3px 5px -1px, rgba(0, 0, 0, 0.12) 3px 3px 5px -1px;
    border-radius: 20px;
    line-height: 25px;
    font-weight: 300;
  }

  .btn-save{
    background-color: #5cb85c;
    color: #ffffff;
    border: none;
    padding: 7px 20px;
    float: right;
    margin-right: 40px;
    margin-top: 35px;
    font-size: 14px;
  }

  .modulesTable table{
    margin: 20px 0 0 0 !important;
  }

  .orgDetails, .deviceDetails, .modulesTable table{
    background: #ffffff;
    font-size: 14px;
    line-height: 30px;
    color: #333333;
    margin-top: 20px;
    box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15)!important;
    border-radius: 10px;
    padding: 30px;
    margin: 20px;
  }

  .deviceDetails{
    background: #ffffff;
    font-size: 14px;
    line-height: 30px;
    color: #333333;
    margin-top: 20px;
    padding: 20px;
    margin: 20px 40px 0 40px;
  }

  .teamDetails{
    background: #ffffff;
    font-size: 14px;
    line-height: 30px;
    color: #333333;
    margin-top: 20px;
    padding: 20px;
    margin: 20px 40px 0 40px;
  }

  .routeDetails{
    background: #ffffff;
    font-size: 14px;
    line-height: 30px;
    color: #333333;
    margin-top: 20px;
    padding: 20px;
    margin: 20px 40px 0 40px;
  }

  #newItem{
    background-color: #5cb85c;
    color: #ffffff;
    border: none;
    padding: 7px 20px;
    float: right;
    margin-right: 40px;
    margin-top: 35px;
    font-size: 14px;
  }

  .orgDetails .table{
    margin:0;
  }

  .table th{
    border-bottom: 1px solid #efefef;
    font-weight: 600;
    color: #555;
    border-top:none;
  }

  .table tr td{
    font-size: 13px;
    font-weight: 400;
    padding: 10px 10px;
    border-top: none;
  }

  .table tr td select{
    font-size: 13px;
  }

  .table tr td input{
    font-size: 13px;
  }

  .destination{
    font-size:13px;
  }

  .destination select{
    font-size:13px;
  }

  .destination input{
    margin-right:5px;
  }

  .modal{
    display: none;
    position: fixed;
    z-index: 999999;
    padding-top: 100px;
    right: 0;
    top: 0;
    background-color: rgba(0,0,0,0.4);
    opacity:1 !important;
  }

  .modal label {
    font-size: 13px;
    letter-spacing: 0px;
    font-weight: 400;
    color: #333333;
}
  
  .modal .modal-header{
    background: #2196F3;
    color: #ffffff;
    border-radius: 0;
    padding: 15px 20px;
  }
  
  .modal .modal-header h4{
    font-size: 13px;
    font-weight: 300;
  }
  
  .modal .modal-header .close{
    color: #2196F3;
    cursor: pointer;
    font-size: 20px;
    float: right;
    background: #ffffff;
    border-radius: 100%;
    width: 21px;
    height: 21px;
    text-align: center;
    padding: 0;
    line-height: 22px;
    font-weight: 300;
  }
  
  .modal .modal-body{
    padding: 15px 15px 7px 15px;
  }
  
  .modal .modal-content{
    background-color: #fefefe;
      margin: auto;
      padding: 0;
      border: 1px solid #888;
      width: 600px;
  }
  
  .modal .modal-content form{
    margin:0 !important;
    padding: 0 15px;
  }
  
  .modal .modal-content form label{
    font-size: 13px;
    font-weight: 400;
  }
  
  .modal .modal-content form .form-control{
    font-size: 14px;
  }

.modal-footer{
	display: block !important;
	padding-bottom: 0 !important;
  padding-top: 0 !important;
  border-top:none;
}

.modal-footer .save{
	width:100%;
	height:100%;
	background-color: #5cb85c;
	border:none;
	color:#ffffff;
	border-radius:0
}

.modal-footer .cancel{
	width:100%;
	background:#ff0000;
	color:#ffffff;
	border-radius:0
}

.modal-footer .cancel, .modal-footer .save{
	cursor: pointer;
	font-size: 14px;
}

.modal .btn-green{
	background-color: #5cb85c;
  color: #ffffff;
  width: 100%;
  border-radius: 0;
  font-size: 13px;
  font-weight: 300;
}

.btn-green{
	background-color: #5cb85c;
  margin: 15px 0;
  color: #ffffff;
  width: auto;
  border-radius: 0;
  font-size: 14px;
}

.modal .btn-red{
	margin: 15px 0;
  width: 100%;
  background: #ff0000;
  border-radius: 0;
  color: #fff;
  font-size: 13px;
  font-weight: 300;
}

.modal .form-control{
	font-size:14px !important;
}

.modal form{
  border-bottom: none;;
}

.btn-reset-dashboard {
  background: #FF0000;
  font-size: 14px;
  margin-right: 10px;
  margin-bottom: 10px;
  float: right;
  margin-top: 35px;
  color: #ffffff;
}

.notice-dashboard-reset {
  background: #ffffff;
  padding: 20px;
  text-align: center;
  right: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 1px 1px 10px rgba(0,0,0,.125);
  color: #333333;
  font-size: 14px;
  display: none;
}

.alarmDetails input[type="text"], .alarmDetails input[type="number"], .alarmDetails select{
  height:40px;
}

#btnYes{
  color: #ffffff;
  border: none;
  font-size: 14px;
  margin:0;
}

.modalTitle{
  font-size:14px;
  color:#333;
  margin-bottom:25px;
}

#btnNo{
  background: #ff0000;
  color: #ffffff;
  border: none;
  font-size: 14px;
  width: 100%;
  border-radius: 0;
  margin:0;
}

#modal_dialog .modal-body{
  padding: 0px 12px;
}

#modal_dialog .modal-body .row{
  padding: 0 15px 15px 15px;
}

#modal_dialog i{
    font-size: 50px;
    color: orange;
    margin-bottom: 15px;
    margin-top:25px;
}

.btnimage{
  display: inline-block;
  padding: 0 !important;
  margin-right: 5px;
}

#GetIconPicker{
  display: inline-block;
    background: rgb(0, 82, 156);
    color: #ffffff;
    border: none;
    padding: 5px 15px;
}

.upload label{
  display: block;
  font-weight: 500;
}

.upload .uploadBtn::-webkit-file-upload-button {
  visibility: hidden;
}

.upload .uploadBtn{
  display:flex;
}

.upload form{
  border-bottom:none;
  padding-bottom: 0 !important;
}

.upload .uploadBtn::before{
    content: 'Bestand selecteren';
    display: inline-block;
    border: solid 1px #ddd;
    border-radius: 3px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    text-align: center;
    font-size: 13px;
}

.upload input[type="submit"]{
  background: green;
    color: #ffffff;
    border: none;
    padding: 7px 15px;
}

.nightnurse_devices{
  margin: 20px 2.5rem 0px 2.5rem;
}

.nightnurse_devices .device{
  background: #ffffff;
  padding: 10px;
  color: #000000;
  font-size: 13px;
  font-weight: 400;
  vertical-align: middle;
  padding-left: 20px !important;
  border-top: 1px solid #efefef;
  border-bottom: none;
}

.nightnurse_devices .device span.device_name{
  font-weight: 500;
  background: #f1f1f1;
  width: 100%;
  display: block;
  padding: 10px;
  margin: 10px 0;
}

.accordion {
  background-color: #fff;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
}

.accordion:after {
  content: '\02795'; /* Unicode character for "plus" sign (+) */
  font-size: 13px;
  color: #777;
  float: right;
  margin-left: 5px;
}

.accordion.active:after {
  content: "\2796"; /* Unicode character for "minus" sign (-) */
}

.active, .accordion:hover {
  background-color: #eee;
}

.panel {
  display:none;
  padding: 0 18px;
  background-color: white;
  transition: max-height 0.2s ease-out;
}

.alert-success, .alert-danger{
  display:none;
  margin: 30px 40px 30px 40px;
}

.nightnurse_devices table tr{
  border-bottom: solid 1px #eee;
}

.nightnurse_devices table tr td{
  padding: 10px 0;
}

.msg_no_location{
  display:none;
}

.btn-truncate{
  background:#FF0000;
  color: #ffffff;
  font-size: 13px;
  float: right;
  margin-right: 40px;
  margin-top: 40px;
}

.noStatus{
  color: #ff0000;
  font-size: 100px;
  width: 100%;
  text-align: center;
}

.btn-backtodash{
  float: none;
  margin: 0px 0px 0px;
  font-size: 12px;
  border-bottom: solid 1px #ddd;
  width: 100%;
  padding: 15px;
  text-align: left;
}

.btn-backtodash:hover{
  border-bottom: solid 1px #ddd;
}