.filters{
    padding: 20px 40px 15px 30px;
    color: #333333;
    min-height: 70px;
    background: #ffffff;
    box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15)!important;
    margin-top: 30px;
    border-radius: 10px;
    margin: 0px 25px 30px 25px;
    font-size:13px;
    font-weight: 300;
}

.filters select{
/* styling */
  background-color: white;
  border: thin solid blue;
  border-radius: 4px;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;

  /* reset */

  margin: 0;      
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;

  background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%), radial-gradient(#fff 70%, transparent 72%);
  background-position: calc(100% - 15px) calc(1em + 4px), calc(100% - 10px) calc(1em + 4px), calc(100% - 0.5em) 1.5em;
  background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
  background-repeat: no-repeat;
}

.filters input, .filters select{
    padding: 0;
    width: auto !important;
    min-width: 160px;
    border-bottom: none;
    padding: 5px !important;
    margin-left: 10px;
    border: 1px solid #eee !important;
    padding: 10px 10px !important;
    margin-right:20px;
    font-size: 12px;
    border-radius: 10px;
}

.chart{
    background: #ffffff;
    padding: 25px;
    margin: 0 25px 30px 25px;
    box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15)!important;
}

#NewAlarmsDay{
    display:none;
}

.alarmDetails {
    background: #ffffff;
    font-size: 13px;
    line-height: 30px;
    color: #333333;
    margin-top: 20px;
    padding: 20px 40px;
}

.alarmDetails table{
    width:100%;
}

.alarmDetails table tr td{
    font-size:13px;
}

.performanceTotal{
    margin: 0 35px;
}

.performanceBlock{
    background: #ffffff;
    color: #333333;
    box-shadow: 0 1px 2px rgba(0,0,0,0.15);
    padding:20px;
    margin-right: 25px;
    text-align: center;
    box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15)!important;
    border-radius: 10px;
    max-width: 350px;
}

.performanceBlock i{
    font-size: 25px;
    float: left;
    margin-right: 20px;
    background: #0085CA;
    color: #ffffff;
    border-radius: 100%;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
    padding: 5px;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 32px;
}

.performanceBlock .block_title{
    font-weight: 500;
    font-size: 14px;
}

.performanceBlock .block_value{
    font-weight: 600;
    font-size: 14px;
    color:#0085CA;
}

.search-btn{
    background: #0085CA;
    color: #ffffff;
    border-radius: 100%;
    width: 35px;
    height: 35px;
    text-align: center;
    display: inline-block;
    padding: 9px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
}

.ui-datepicker .ui-state-highlight, .ui-widget-content .ui-state-highlight, .ui-widget-header .ui-state-highlight {
    border: solid 1px #0085CA !important;
    background: #0085CA !important;
    color: #ffffff !important;
}

.ui-datepicker .ui-widget-header {
    border: 1px solid #fff !important;
    background: #ffffff !important;
    color: #333 !important;
    font-weight: 100 !important;
}

.ui-datepicker .ui-datepicker th{
    font-weight: 100 !important;
}

.ui-datepicker .ui-datepicker td, .ui-datepicker td a{
    text-align: center !important;
}

.react-datepicker-popper{
    z-index:999999 !important;
}

/* .alarmDetails table tr td:first-child{
    font-weight: bold;
} */

.no-check{
    color: #ffffff;
    background: #ff0000;
    box-shadow: rgba(0, 0, 0, 0.2) 3px 3px 5px -1px, rgba(0, 0, 0, 0.14) 3px 3px 5px -1px, rgba(0, 0, 0, 0.12) 3px 3px 5px -1px;
    border-radius: 100%;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 26px !important;
}

.check{
    color: #ffffff;
    background: green;
    box-shadow: rgba(0, 0, 0, 0.2) 3px 3px 5px -1px, rgba(0, 0, 0, 0.14) 3px 3px 5px -1px, rgba(0, 0, 0, 0.12) 3px 3px 5px -1px;
    border-radius: 100%;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 26px !important;
}

progress[value] {
    -webkit-appearance: none;
    appearance: none;
    width: 65%;
    height: 20px;
    margin-right: 15px;
    float: left;
    margin-top: 5px;
  }

progress[value]::-webkit-progress-bar {
    background-color: #f0f0f0;
    height: 10px;
    border-radius: 10px;
  }

.batGreen[value]::-webkit-progress-value {
    background: green;
    border-radius: 10px; 
    height: 10px;
    background-size: 35px 20px, 100% 100%, 100% 100%;
}

.batOrange[value]::-webkit-progress-value {
    background: orange;
    border-radius: 10px; 
    height: 10px;
    background-size: 35px 20px, 100% 100%, 100% 100%;
}

.batRed[value]::-webkit-progress-value {
    background: red;
    border-radius: 10px; 
    height: 10px;
    background-size: 35px 20px, 100% 100%, 100% 100%;
}

.heartbeatmap-overlay{
    display:none;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    width: 100%;
    height:100%;
    z-index: 999998;
}

.closeMap{
    position: absolute;
    z-index: 999999;
    right: 10px;
    top: 8px;
    font-size: 20px;
    color: #ffffff;
    cursor: pointer;
}

.heartbeatmap{
    display:none;
    width: 500px;
    height: 400px;
    background: rgb(255, 255, 255);
    position: fixed;
    left: 0px;
    right: 0px;
    margin-left: auto;
    margin-right: auto;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 999998;
}

.heartbeatmap h3{
    font-size: 14px;
    background: #0085CA;
    margin: 0;
    color: #ffffff;
    padding: 10px 15px;
}

.status-accepted{
    display: inline-block;
    text-align: center;
    vertical-align: top;
}

.status-accepted span{
    display: block;
    margin-top: 15px;
    width:200px;
    color: #777777;
}

.status-finished{
    display: inline-block;
    text-align: center;
    vertical-align: top;
}

.status-finished span{
    display: block;
    width: 100px;
    margin-top: 15px;
    width:200px;
    color: #777777;
}

.status-accepted i.fa-check-circle{
    color:#009f07;
    font-size: 30px;
}

.status-accepted i.fa-times-circle{
    color:#ff0000;
    font-size: 30px;
}

.status-finished i{
    font-size: 30px;
}

.status-teams{
    display: inline-flex;
    vertical-align: top;
}

.status-teams .badge-pill{
    font-size: 14px;
    padding: 5px 0px;
    color: #777777 !important;
}

.status-teams i{
    font-size:30px;
    padding-bottom:10px;
    display: block;
    margin: 0px 20px;
}

.status-teams .teamReceived{
    color:#009f07;
}

.status-teams .teamNotReceived{
    color:#c7cad9;
}

.border-divider{
    display: inline-block;
    border-top:solid 1px #c7cad9;
    height:1px;
    width:100px;
    margin:0 20px 0 20px;
}

.status{
    margin: 0px 40px 40px 40px;
    text-align: center;
    display: inherit;
    background: #ffffff;
    box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15)!important;
    border-radius: 10px;
    padding: 35px 0;
}

.status-info{
    display: block;
    min-width: 600px;
    margin-top: 50px;
}

.showAcceptedName{
    display:block;
}

.hideAcceptedName{
    display:none;
}

.alarm-route{
    text-align: center;
    background: rgba(199, 202, 217, 0.1);
    padding: 30px 100px;
    border: solid 1px rgb(199, 202, 217);
    width: fit-content;
    margin: auto;
    position: relative;
}

.alarm-route .closeroute{
    position: absolute;
    right: -7px;
    top: -7px;
    color: #777777;
    font-size: 20px;
    cursor: pointer;
}

.status h2{
    text-align: center;
    margin-top: 0px;
    padding-top: 0px;
    margin-bottom: 30px;
}

.alarm-route h2{
    text-align: center;
    margin-top: 0px;
    margin-bottom:20px;
}

.routeteam{
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    color: #777777;
}

.routeteam .team{
    display: inline-block;
    vertical-align: middle;
    font-size: 14px;
    position: relative;
}

.routeteam .team i{
    display: block;
    font-size: 25px;
    margin-bottom: 10px;
}

.routeteam .team .teamsequence{
    display: block;
    font-size: 12px;
}

.routeteam .sequence-arrow{
    display: inline-block;
    padding: 0 20px;
    font-size: 20px;
}

.status-teams .team{
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
}

.status-team .sequence-arrow{
    display: inline-block;
    font-size: 20px;
    color:#777777;
}

.status-team .sequence-arrow i{
    font-size: 20px;
}

.routeteam:last-child .sequence-arrow{
    display:none !important;
}

.status-team:last-child .sequence-arrow{
    display:none !important;
}

.route-info{
    color: #72a7cf;
    cursor: pointer;
}

.team-users{
    background: #f1f3f7;
    padding: 20px 20px;
    border: solid 1px rgb(199, 202, 217);
    color:#777777;
    text-align: left;
    position: absolute;
    margin-bottom: 20px;
    display:none; 
}

.team-users div{
    margin-bottom: 10px;
}

.help{
    background: #eee;
    border: solid 1px #ccc;
    padding: 10px 20px;
    margin: 20px 2.5rem -0 2.5rem;
    color:#333;
}

.dt-button{
	padding: 10px 15px !important;
}

table.dataTable.nowrap th{
    padding: 20px 20px;
    border-bottom: 1px solid #efefef;
    font-weight: 600;
    color: #555;
}

table.dataTable.nowrap thead tr{
    background: #ffffff;
}

.dataTables_wrapper.no-footer .dataTables_scrollBody{
    border-bottom: none;
}

.react-datepicker-wrapper, .react-datepicker__tab-loop{
    display: inline-block !important;
    width: auto !important;
}

.chart-view{
    font-weight: 100;
    color: #333;
    padding: 30px 30px 30px 30px !important;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15)!important;
    margin: 0 25px;
}

.ALARM_IN_BED_DETECTION{
    background: #d0b2cf !important;
}

.ALARM_OUT_OF_BED_DETECTION{
    background: #b66f2b !important;
}

.ALARM_OUT_OF_ROOM_DETECTION{
    background: #003C72 !important;
}

.ALARM_SOEB_DETECTION{
    background: #ecdb0e !important;
}

.ALARM_MAN_DOWN_DETECTION{
    background: #a01407 !important;
}

.ALARM_MISSING_BED_DETECTION{
    background: #dc3545 !important;
}

.ALARM_PERSON_VISIBLE_DETECTION{
    background: #25b100 !important;
}

.ALARM_IN_BATHROOM_DETECTION{
    background: #01ace1 !important;
}

.ALARM_NO_DETECTION{
    background: #808080 !important;
}

.ALARM_STAFF_ENTERING_ROOM_DETECTION{
    background: #000000 !important;
}

.alarm-no-data{
    background: #808080 !important;
}

.sg-task-content{
    display: none !important;
}

.sg-table-body-cell{
    font-weight: 400 !important;
    color:#000;
}

.sg-table-header-cell.svelte-87uanl{
    font-weight: 600 !important;
}

.sg-timeline-body{
    overflow: hidden !important;
}

.legend{
    text-align: center;
    padding: 15px 0;
    font-size: 14px;
    color:#333;
}

.legend-item{
    display: inline-block;
    margin-right:20px; 
}

.legend-block{
    width:10px;
    height: 10px;
    display: inline-block;
    margin-right:5px;
    border-radius: 100%;
    box-shadow: rgba(0, 0, 0, 0.2) 3px 3px 5px -1px, rgba(0, 0, 0, 0.14) 3px 3px 5px -1px, rgba(0, 0, 0, 0.12) 3px 3px 5px -1px;
}

.legend-day span{
    width:10px !important;
    height: 10px !important;
    box-shadow: rgba(0, 0, 0, 0.2) 3px 3px 5px -1px, rgba(0, 0, 0, 0.14) 3px 3px 5px -1px, rgba(0, 0, 0, 0.12) 3px 3px 5px -1px;
}

.read-only{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 10 !important;
}

.employee .contact-icon {
    background: #0085CA;
    color: #ffffff;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    font-size: 16px;
    font-weight: 600;
    display: inline-block;
    margin-right: 15px;
    box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
}

.employee span{
    font-size: 20px;
    font-weight: 500;
    display: inline-block;
    vertical-align: middle;
}

.employee .currentTeams{
    margin: 5px 55px;
}

.employee .currentPhone{
    margin: -5px 55px 15px 55px;
}

.status_icon{
    float: left;
    font-size: 25px;
    margin-top: 15px;
    margin-right: 30px;
    color: #0085CA;
}

.status_title{
    font-size: 16px;
    font-weight: 600;
}

.streamtitle{
    font-size: 15px;
    border-bottom: solid 1px #ddd;
    padding-bottom: 5px;
    margin-bottom: 20px;
    font-weight: 500;
}

.select-queues-groups{
    width: 100%;
    position: absolute;
    z-index: 11;
}

.select-queues-groups .list-queue-groups {
    background: #ffffff;
    /* width: 100%;
    max-width: 480px;
    position: absolute;
    z-index: 999999;
    left: 0;
    right: 0;
    top: 0;
    padding: 15px;
    margin-left: auto;
    margin-right: auto; */
    padding:30px;
    margin: 0 60px 0 30px;
    box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15)!important;
    -webkit-box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15)!important;
    /* border: solid 1px #b7bcd1;
    -webkit-transform: translateY(50%);
    transform: translateY(50%); */
}
  
  .select-queues-groups h1{
    text-align: center;
    margin-bottom: 20px;
  }
  
  .select-queues-groups table{
    width:100%;
  }

  .select-queues-groups button{
    display: block;
    margin: auto;
    margin-top: 20px;
  }

  .select-queues-groups tbody tr, .select-queues-groups tbody tr td{
    border:none;
  }

  .select-queues-groups tbody tr:nth-child(even){
    background: #f8f8f8;
  }

  strong.call{
    color: #333;
    font-weight: 300;
    margin-left: 15px;
  }

  .alarm-employees, .alarm-logs, .add_note{
    background: rgb(255, 255, 255);
    border-radius: 10px;
    margin: 20px 40px;
    padding: 30px;
    box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15)!important;
  }

  .add_note form{
    padding:0;
    border-bottom: none;
  }

  .alarm-status{
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    margin-right: 10px;
    box-shadow: rgba(0, 0, 0, 0.2) 3px 3px 5px -1px, rgba(0, 0, 0, 0.14) 3px 3px 5px -1px, rgba(0, 0, 0, 0.12) 3px 3px 5px -1px;
  }

  .alarmnotes{
    margin: 20px 40px;
  }

/* .roominsights .sg-table.svelte-87uanl, .roominsights .sg-resize.svelte-v0xg82, .roominsights .sg-columns.svelte-19kvsxe, .roominsights .sg-rows.svelte-8uspd{
    display: none;
} */